import WorkerService from './workerService';
import SubscriptionManager from './subscriptionManager';
import WorkerServiceConfig from './workerServiceConfig';
import PiiService from './piiService';

// Define this var, which prevents typescript errors.  Webpack will set this for us
declare var process : {
  env: {
    NODE_ENV: string,
    MIX_PUSH_URL_DEV: string,
    MIX_PUSH_URL_PROD: string
  }
};

// Determine push URL based on .env
let pushUrl = '';
switch (process.env.NODE_ENV) {
  case 'development':
    pushUrl = process.env.MIX_PUSH_URL_DEV;
    break;
  case 'production':
    pushUrl = process.env.MIX_PUSH_URL_PROD;
    break;
}

// Subscription manager
const subMgr = new SubscriptionManager(
  new PiiService(),
  pushUrl
);

// Worker service
const wsConfig: WorkerServiceConfig = {
  vapidPublicKey: 'BKuqgocfaaXW65SZy7yPFqxXsvBfBEOUtmbVS7wykQYSdtM6ga0voH6y/Ho9B1rXjUY5MArmXd+wH6qwAQQ/7PY=',
  swJs: '/sw.js'
};
const ws = new WorkerService(
  subMgr,
  wsConfig
);

// Register service worker and ask for permission
ws.registerServiceWorker();
ws.subscribe();