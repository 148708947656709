import userInterface from './userInterface';

export default class PiiService {
  private path: string = '';
  private query: string = '';
  private setFromOutside = {};

  getPii(): userInterface {
    this.path = window.location.pathname.toLowerCase();
    this.query = window.location.search.toLowerCase();

    return {
      vt: this.findField('vt'),
      cid: this.findField('cid'),
      afid: this.findField('afid'),
      sid: this.findField('sid'),
      fname: this.findField('fname'),
      lname: this.findField('lname'),
      email: this.findField('email'),
      zid: this.findField('zid'),
      userAgent: navigator.userAgent,
      currentDomain: window.location.hostname
    };
  }

  private findField(field: string): string {
    // If an outside service set a pii field, just use that
    if (this.setFromOutside.hasOwnProperty('field')) {
      return this.setFromOutside[field];
    }

    // Search CI path for these variables
    if (['vt','cid','afid','sid'].indexOf(field)) {
      return this.findPathPiece(field);
    }

    // Search query string for these
    if (['fname','lname','email','zid'].indexOf(field)) {
      return this.getParameterByName(field);
    }

    return '';
  }

  /**
   * Allow hosting site to force pii values.  This could be abused by dev console
   * @param {string} field
   * @param {string} val
   */
  setPiiField(field: string, val: string) {
    this.setFromOutside[field] = val;
  }

  /**
   * Find CI path variables
   * @param {string} val
   * @returns {string}
   */
  private findPathPiece(val: string): string {
    val = '/' + val + '/';

    let pieceStart: number = this.path.indexOf(val);
    let pieceEnd: number = 0;
    let piece: string = '';

    if (pieceStart) {
      pieceEnd = this.path.indexOf('/', pieceStart + val.length);

      // If there is another slash after this variable
      if (pieceEnd > pieceStart) {
        piece = this.path.substr(pieceStart + val.length, pieceEnd - pieceStart + val.length);
      }
      // There is no following slash, so assume this is the last variable on the path.
      else {
        piece = this.path.substr(pieceStart + val.length);
      }
    }

    return piece;
  }

  /**
   * Extract query string parameters
   * @param {string} name
   * @returns {string}
   */
  private getParameterByName(name: string): string {
    name = name.replace(/[\[\]]/g, "\\$&");

    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    let results = regex.exec(this.query);

    if (!results) return '';
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}

(<any>window).webPushPiiService = PiiService;