import SubscriptionManager from './subscriptionManager';
import WorkerServiceConfig from './workerServiceConfig';

export default class WorkerService
{
  private isPushEnabled:boolean = false;

  constructor(private subManager: SubscriptionManager, private config: WorkerServiceConfig) {
  }

  registerServiceWorker () {
    if (!('serviceWorker' in navigator)) {
      console.log('Service workers aren\'t supported in this browser.');
      return
    }

    navigator
      .serviceWorker
      .register(this.config.swJs)
      .then(() => this.initialiseServiceWorker())
      .catch((e) => console.log('Error during worker registration', e));
  }

  private initialiseServiceWorker () {
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      console.log('Notifications aren\'t supported.');
      return
    }

    if (Notification.permission === 'denied') {
      console.log('The user has blocked notifications.');
      return
    }

    if (!('PushManager' in window)) {
      console.log('Push messaging isn\'t supported.');
      return
    }

    navigator
      .serviceWorker
      .ready
      .then(registration => {
        registration
          .pushManager
          .getSubscription()
          .then(subscription => {
              if (!subscription) {
                return;
              }

              this.isPushEnabled = true;

              // Don't think we need this, as subscribe is always called, which will call update subscription
              // this.subManager.updateSubscription(subscription);
            })
            .catch(e => {
              console.log('Error during getSubscription()', e)
            });
      });
  }

  /**
   * Subscribe for push notifications.  This will ask for permission from the user.
   */
  subscribe () {
    // When the service worker is done being registered...
    navigator.serviceWorker.ready.then(registration => {
      // Don't do this if we are already subscribed
      if (this.isPushEnabled) {
        return;
      }

      const options: PushSubscriptionOptionsInit = {
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array(this.config.vapidPublicKey)
      };

      registration.pushManager.subscribe(options)
        .then(subscription => {
          this.isPushEnabled = true;

          this.subManager.updateSubscription(subscription)
        })
        .catch(e => {
          if (Notification.permission === 'denied') {
            console.log('Permission for Notifications was denied')
          } else {
            console.log('Unable to subscribe to push.', e)
          }
        })
    })
  }

  /**
   * Unsubscribe from push notifications.
   */
  unsubscribe () {
    navigator.serviceWorker.ready.then(registration => {
      registration.pushManager.getSubscription().then(subscription => {
        if (!subscription) {
          this.isPushEnabled = false;
          return
        }

        subscription.unsubscribe().then(() => {
          this.subManager.deleteSubscription(subscription);

          this.isPushEnabled = false;
        }).catch(e => {
          console.log('Unsubscription error: ', e);
        })
      }).catch(e => {
        console.log('Error thrown while unsubscribing.', e)
      })
    })
  }

  /**
   * https://github.com/Minishlink/physbook/blob/02a0d5d7ca0d5d2cc6d308a3a9b81244c63b3f14/app/Resources/public/js/app.js#L177
   *
   * @param  {String} base64String
   * @return {Uint8Array}
   */
  private urlBase64ToUint8Array (base64String): Uint8Array {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }

    return outputArray
  }
}
